import { createRouter, createWebHistory } from 'vue-router'
import Wrapper from '@app/Wrapper.js'
import store from './store.js'
import routes from './routes.js'

Munio.Auth = class extends Wrapper {
  get name() {
    return 'Auth'
  }

  store() {
    return store
  }

  component() {
    return import('./App.vue')
  }

  async router() {
    const router = createRouter({
      history: createWebHistory(`/${Munio.config.i18n.routeKey}`),
      routes,
    })

    router.beforeEach((to, from, next) => {
      if (to.meta && to.meta.title) {
        document.title = to.meta.title + ' - EcoOnline LMS'
      }

      return next()
    })

    return router
  }
}
