import $ from 'jquery'

// Base primitive types
type PrimitiveValue = string | number | boolean | null | undefined

// Recursive type for nested structures (arrays or objects)
type URLParamValue = PrimitiveValue | NestedObject | URLParamValue[]

// Object with string keys and nested values
interface NestedObject {
  [key: string]: URLParamValue
}

// URLParams union type
export type URLParams =
  | string
  | [string, PrimitiveValue][] // Tuple array for key-value pairs
  | URL
  | URLSearchParams
  | HTMLFormElement
  | null
  | undefined
  | NestedObject

// Convert primitive values to strings
function getValue(value: PrimitiveValue): string {
  if (value === null || value === undefined) return ''
  if (value === true) return '1'
  if (value === false) return '0'
  return value.toString()
}

// Recursively process nested objects and arrays into URLSearchParams
function processNestedParams(params: URLSearchParams, key: string, value: URLParamValue, parentKey: string = ''): void {
  const currentKey = parentKey ? `${parentKey}[${key}]` : key

  if (value === undefined || value === null) {
    params.delete(currentKey)
    return
  }

  if (Array.isArray(value)) {
    if (value.length === 0) {
      params.set(`${currentKey}[]`, '')
      return
    }

    value.forEach((item, index) => {
      if (typeof item === 'object' && item !== null && item !== undefined && !Array.isArray(item)) {
        processNestedParams(params, String(index), item, currentKey)
      } else {
        params.append(`${currentKey}[]`, getValue(item as PrimitiveValue))
      }
    })
    return
  }

  if (typeof value === 'object' && value !== null) {
    Object.entries(value).forEach(([subKey, subValue]) => {
      processNestedParams(params, subKey, subValue, currentKey)
    })
    return
  }

  params.set(currentKey, getValue(value))
}

export function urlParams(query: URLParams = window.location.search): URLSearchParams {
  if (query instanceof URL) {
    return new URLSearchParams(query.searchParams)
  }

  if (query instanceof URLSearchParams) {
    return new URLSearchParams(query)
  }

  if (query instanceof HTMLFormElement) {
    return new URLSearchParams($(query).serialize())
  }

  if (query === null || query === undefined) {
    return new URLSearchParams()
  }

  if (Array.isArray(query)) {
    const filtered = query
      .filter((item): item is [string, PrimitiveValue] => item[1] !== undefined && item[1] !== null)
      .map(([key, value]) => [key, getValue(value)])

    return new URLSearchParams(filtered)
  }

  if (typeof query === 'string') {
    return new URLSearchParams(query)
  }

  const params = new URLSearchParams()

  Object.entries(query).forEach(([key, value]) => {
    processNestedParams(params, key, value)
  })

  return params
}

export function queryString(query: URLParams = window.location.search): string {
  return urlParams(query).toString()
}

// Assign to window
window.Munio.urlParams = urlParams
window.Munio.queryString = queryString
