<template>
  <div class="max-w-full">
    <dt class="mdl-overline">{{ term }}</dt>
    <dd
      class="flex mdl-color-text--grey-900"
      :class="{ truncate, 'select-all': select, 'whitespace-normal': multiline }"
    >
      <template v-if="isDescriptionTooLong">
        <div class="flex-wrap">
          <span>{{ descriptionParts[0] }}</span>
          <span class="opaque" v-if="can('munio')">
            {{ descriptionParts[1] }}
            <Icon name="warning" small class="mdl-color-text--danger" />
          </span>
        </div>
      </template>
      <template v-else-if="description || (placeholder && !hasDefaultSlot)">
        {{ description || (hasDefaultSlot ? '' : placeholder) }}
      </template>
      <Copy v-if="copy && description" :text="description" />
      <slot></slot>
    </dd>
  </div>
</template>

<script>
import { defineComponent, useSlots } from 'vue'
import Copy from '@component/Copy.vue'
import Icon from '@component/Icon.vue'

import useSlotCheck from '@composable/useSlotCheck'

export default defineComponent({
  components: {
    Copy,
    Icon,
  },

  props: {
    term: { type: String, required: true },
    description: { type: [Number, String] },
    placeholder: { type: String, default: '\u2014' },
    multiline: { type: Boolean },
    truncate: { type: Boolean },
    select: { type: Boolean, default: true },
    copy: { type: Boolean },
    length: { type: Number },
  },

  setup() {
    const can = window.can
    const hasDefaultSlot = useSlotCheck(useSlots().default)

    return { can, hasDefaultSlot }
  },

  computed: {
    isDescriptionTooLong() {
      return this.description && this.length && this.description.length > this.length
    },

    descriptionParts() {
      if (this.isDescriptionTooLong) {
        return [String(this.description).substring(0, this.length), String(this.description).substring(this.length)]
      }

      return [this.description || this.placeholder, undefined]
    },
  },
})
</script>
