<template>
  <div class="mdl-phonenumber">
    <div key="display" v-if="display && results.national" class="mdl-phonenumber__display" v-bind="$attrs">
      <div class="mdl-phonenumber__value">
        <span class="mdl-phonenumber__value-country">{{ flag(results.regionCode) }}</span>
        <span class="mdl-phonenumber__value-number">{{ results.national }}</span>
      </div>
    </div>

    <template v-else>
      <MunSelect
        key="country"
        class="mdl-phonenumber__country"
        searchable
        v-model="regionCode"
        :options="countries"
        :disabled="disabled"
        append-to-body
        disable-fuzzy-matching
        @update:model-value="countryCodeChanged"
      />
      <MdlTextfield
        key="number"
        class="mdl-phonenumber__input"
        ref="number"
        v-bind="$attrs"
        v-autofocus="autofocus"
        :model-value="input"
        :floating-label="inputLabel"
        :disabled="disabled"
        :required="required"
        :error="errorMessage"
        @keydown="handleKeyDown"
        @update:model-value="handleInput"
        @change="handleChange"
      />
    </template>
  </div>
</template>

<script>
import { trans } from '@/munio/i18n/index.js'
import {
  parsePhoneNumber,
  getSupportedRegionCodes,
  getAsYouType,
  getCountryCodeForRegionCode,
} from 'awesome-phonenumber'
import MdlTextfield from '@component/mdl/Textfield.vue'
import MunSelect from '@component/Select.vue'
import vAutofocus from '@directive/autofocus.js'

export default {
  inheritAttrs: false,

  name: 'mdl-phonenumber',

  components: {
    MdlTextfield,
    MunSelect,
  },

  props: {
    country: { type: String, default: () => Munio.config.user?.employer?.country || Munio.config.i18n.country },
    modelValue: { type: String, default: '' },
    autofocus: { type: Boolean },
    label: { type: String },
    mobile: { type: Boolean },
    display: { type: Boolean },
    required: { type: Boolean },
    disabled: { type: Boolean },
    error: { type: [Boolean, String], default: false },
  },

  directives: {
    autofocus: vAutofocus,
  },

  data() {
    return {
      inputRegion: null,
      input: null,
      showError: true,
      lastKey: null,
      results: {},
    }
  },

  computed: {
    listeners() {
      return {}
    },
    countries() {
      const supportedCountries = getSupportedRegionCodes()
      let countries = Munio.config.countries
        .filter((c) => supportedCountries.includes(c.iso.toUpperCase()))
        .filter((c) => c.tel)

      countries = Array.from(new Map(countries.map((country) => [country.iso, country])).values())

      return countries
        .map((country) => ({
          id: country.iso,
          label: `${country.flag} ${country.name}`,
          data: country,
        }))
        .sort((a, b) => (a.data.name > b.data.name ? 1 : -1))
    },
    regionCode: {
      get() {
        return this.inputRegion || this.country
      },
      set(value) {
        this.inputRegion = value
      },
    },
    inputLabel() {
      return this.label || trans(this.mobile ? 'Mobile number' : 'Phone number')
    },
    isValid() {
      return this.mobile ? this.results.isMobile : this.results.isValid
    },
    errorMessage() {
      if (!this.showError) {
        return false
      }

      if (this.error) {
        return this.error
      }

      if (this.input && !this.isValid) {
        return this.mobile ? trans('validation.custom.mobile.mobile') : true
      }

      return false
    },
  },

  methods: {
    flag: window.Munio.flag,

    handleKeyDown(event) {
      this.lastKey = event.key
    },
    handleInput(value) {
      this.showError = false
      this.input = value
      this.emitValues(this.parseNumber(value, this.regionCode), false)
    },
    handleChange() {
      this.showError = true
      this.emitValues(this.parseNumber(this.input, this.regionCode), true)
    },
    countryCodeChanged() {
      this.emitValues(this.parseNumber(this.input, this.regionCode), true)
      this.$refs.number.focus()
    },
    updateInput(value) {
      const result = this.parseNumber(value, this.regionCode)
      this.emitValues(result, true)
    },
    parseNumber(input, regionCode) {
      regionCode = (regionCode || this.country).toUpperCase()
      const phoneNumber = parsePhoneNumber(input ?? '', { regionCode })

      if (!phoneNumber.valid) {
        return {
          input: input,
          international: input,
          national: input,
          significant: input,
          e164: input,
          uri: `tel:${input}`,
          asYouType: input,
          type: 'unknown',
          isValid: false,
          isMobile: false,
          isPossible: false,
          regionCode,
          countryCode: getCountryCodeForRegionCode(regionCode),
          value: input,
        }
      }

      const ayt = getAsYouType(regionCode)
      const digits = (input || '').match(/[+\d]/g) || []
      digits.forEach((n) => {
        ayt.addChar(n)
      })

      return {
        input: phoneNumber.number.input,
        international: phoneNumber.number.international,
        national: phoneNumber.number.national,
        significant: phoneNumber.number.significant,
        e164: phoneNumber.number.e164,
        uri: phoneNumber.number.rfc3966,
        asYouType: ayt.number(),
        type: phoneNumber.type,
        isValid: phoneNumber.valid,
        isMobile: phoneNumber.typeIsMobile,
        isPossible: phoneNumber.possible,
        regionCode: phoneNumber.regionCode,
        countryCode: phoneNumber.countryCode,
        value: phoneNumber.number.e164 || phoneNumber.number.input,
      }
    },
    emitValues(phoneNumber, update = false) {
      this.results = phoneNumber
      if (this.results.regionCode) {
        this.regionCode = this.results.regionCode.toLowerCase()
      }
      if (update) {
        this.input = this.results.national
      }
      this.$nextTick(() => {
        this.$emit('update', this.results)
        this.$emit('update:modelValue', this.results.value)
      })
    },
  },

  watch: {
    modelValue(newValue, oldValue) {
      if (oldValue !== newValue && !this.$refs.number.isFocused()) {
        this.updateInput(newValue)
      }
    },
  },

  mounted() {
    if (this.modelValue) {
      this.updateInput(this.modelValue)
    }
  },
}
</script>
