import Wrapper from '@app/Wrapper.js'

Munio.IntegrationActionsInspector = class IntegrationActionsInspector extends Wrapper {
  constructor(selection, integration) {
    super(selection, {
      integration,
    })
  }

  component() {
    return import('./App.vue')
  }
}
