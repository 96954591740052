<script setup lang="ts">
import { computed, useAttrs } from 'vue'

const props = defineProps<{
  header?: boolean
  content?: boolean
  divide?: boolean
  disabled?: boolean
  href?: string
}>()

const emit = defineEmits<{
  (e: 'click', event: MouseEvent | KeyboardEvent): void
}>()

const attrs = useAttrs()
const classes = computed(() => [
  attrs.class,
  {
    disabled: props.disabled,
    'dropdown-header': props.header,
  },
])

function handleClick(event: MouseEvent | KeyboardEvent) {
  if (!props.href) {
    event.preventDefault()
  }

  if (props.disabled) {
    event.preventDefault()
    event.stopPropagation()

    return
  }

  emit('click', event)
}
</script>

<template>
  <li v-if="content || $slots.content">
    <slot />
    <slot name="content"></slot>
  </li>

  <li v-else-if="header" :class="classes" @click="handleClick">
    <slot></slot>
  </li>

  <li v-else-if="divide" role="separator" class="divider" @click="handleClick"></li>

  <li v-else-if="href" :class="classes">
    <a :href="href" @click="handleClick">
      <slot></slot>
    </a>
  </li>

  <li v-else :class="classes" @click="handleClick">
    <a v-if="disabled" aria-disabled="true" style="pointer-events: none" @click.prevent.stop>
      <slot></slot>
    </a>
    <a v-else href>
      <slot></slot>
    </a>
  </li>
</template>
