import { useStorage as vueUseStorage } from '@vueuse/core'
import { decode, encode } from '@/munio/storage.js'

export function useStorage(key: string, defaults: any, storage = localStorage, options = {}) {
  return vueUseStorage(
    key,
    defaults,
    storage,
    Object.assign(
      {
        serializer: {
          read: (value: any) => decode(value),
          write: (value: any) => encode(value),
        },
      },
      options,
    ),
  )
}
