import { route } from '@/munio/http'

class Mobile {
  constructor(number) {
    this.number = number
  }

  get() {
    return Munio.$http.get(route('api.web.mobile'), { number: this.number })
  }

  submit(reverify = false) {
    return Munio.$http.post(route('api.web.mobile.submit'), { number: this.number, reverify })
  }

  verify(code) {
    return Munio.$http.post(route('api.web.mobile.verify'), { number: this.number, code })
  }
}

export default Mobile
