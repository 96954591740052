import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios'
import $ from 'jquery'

export * from './route'
export * from './urlParams'

const { Munio, csrf_token, xsrf_token } = window

export type RequestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
export type RequestData = URLSearchParams | Record<string, unknown>
export type RequestHeaders = AxiosRequestHeaders
export type Request<T> = Promise<AxiosResponse<T>> & {
  cancel(reason?: unknown): void
}

export class Http {
  get<T>(url: string, data?: RequestData, headers?: RequestHeaders, config?: object): Request<T> {
    return request('GET', url, data, headers, config)
  }

  post<T>(url: string, data?: RequestData, headers?: RequestHeaders, config?: object): Request<T> {
    return request('POST', url, data, headers, config)
  }

  put<T>(url: string, data?: RequestData, headers?: RequestHeaders, config?: object): Request<T> {
    return request('PUT', url, data, headers, config)
  }

  patch<T>(url: string, data?: RequestData, headers?: RequestHeaders, config?: object): Request<T> {
    return request('PATCH', url, data, headers, config)
  }

  delete<T>(url: string, data?: RequestData, headers?: RequestHeaders, config?: object): Request<T> {
    return request('DELETE', url, data, headers, config)
  }
}

export const $http = new Http()

function request<T>(
  method: RequestMethod,
  url: string,
  data?: RequestData,
  headers?: RequestHeaders,
  config?: object,
): Request<T> {
  const meth = method.toLowerCase()
  const controller = new AbortController()
  const options = Object.assign(
    {},
    {
      method: meth,
      url,
      data,
      headers,
      params: meth === 'get' ? data : {},
      signal: controller.signal,
    },
    config,
  )

  const req = axios.request(options) as Request<T>
  req.cancel = (reason: string) => controller.abort(reason)
  return req
}

export function url(path?: string) {
  if (path === undefined) {
    return new URL(window.location.toString())
  }

  return new URL(window.Munio.config.baseurl + '/' + path)
}

function handleResponse(response: AxiosResponse) {
  Munio.handleJSONResponseData(response.data)

  if (response.headers?.hasOwnProperty('x-counters')) {
    Munio.Counters.reload(response.headers['x-counters'])
  }
}

// Setup Axios
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.interceptors.request.use(function (config) {
  config.headers['X-CSRF-TOKEN'] = csrf_token()
  config.headers['X-XSRF-TOKEN'] = xsrf_token()
  config.headers['X-Language'] = Munio.config.i18n.key
  return config
})

axios.interceptors.response.use(
  function (response) {
    handleResponse(response)

    return response
  },
  function (error) {
    if (error.response) {
      handleResponse(error.response)
    }

    return Promise.reject(error)
  },
)

// Setup jQuery
$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': csrf_token(),
    'X-XSRF-TOKEN': xsrf_token(),
    'X-Language': Munio.config.i18n.key,
  },
})

$(document).ajaxSuccess(function (event, jqxhr, options, data) {
  Munio.handleJSONResponseData(data)

  const countersUlid = jqxhr.getResponseHeader('x-counters')

  if (countersUlid) {
    Munio.Counters.reload(countersUlid)
  }
})

Munio.$http = $http
