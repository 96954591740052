<template>
  <div :class="chipClasses" :tabindex="tabindex" v-bind="$attrs" @click="onClick" @keydown.delete="onDelete">
    <img v-if="image" :class="contactClasses" :src="image" alt="" />
    <Icon v-else-if="icon" :name="icon" :class="contactClasses" />
    <div v-else-if="contact" :class="contactClasses">{{ contactText }}</div>

    <template v-if="deletable">
      <div class="mdl-chip__text" @click.stop="onClick">
        <slot></slot>
      </div>
      <div class="mdl-chip__action" @click.stop="onDelete">
        <i class="material-icons">{{ deleteIcon }}</i>
      </div>
    </template>
    <div v-else class="mdl-chip__text" @click.stop="onClick">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Icon from '@component/Icon.vue'
import { colorCssName } from '@/munio/utils/index.js'

export default {
  inheritAttrs: false,

  components: { Icon },

  props: {
    colorText: { type: String },
    colorBg: { type: String },
    contact: { type: String },
    contactColorText: { type: String },
    contactColorBg: { type: String },
    image: { type: String },
    icon: { type: String },
    contactClass: { type: String },
    disabled: { type: Boolean },
    deletable: { type: Boolean, default: false },
    clickable: { type: Boolean, default: undefined },
    deleteIcon: { type: String, default: 'clear' },
    highlight: { type: Boolean, default: false },
    tabindex: { type: [String, Number], default: 0 },
  },

  computed: {
    chipClasses() {
      return {
        'mdl-chip': true,
        'mdl-chip--contact': this.contact || this.image || this.icon,
        'mdl-chip--deletable': this.deletable,
        'mdl-chip--highlight': this.highlight,
        'is-clickable': this.isClickable,
        opaque: this.disabled,
        [colorCssName(this.colorBg)]: this.colorBg,
        [colorCssName(this.colorText, true)]: this.colorText,
      }
    },

    isClickable() {
      if (this.disabled) {
        return false
      }

      if (this.clickable === undefined) {
        return this.$attrs.hasOwnProperty('onClick')
      }

      return this.clickable
    },

    contactText() {
      if (this.contact) {
        return this.contact.substring(0, 2).toLocaleUpperCase()
      }
      return null
    },

    contactClasses() {
      const contactBg = this.contactColorBg || this.colorBg
      const contactText = this.contactColorText || this.colorText

      return {
        'mdl-chip__contact': true,
        'material-icons': this.icon,
        [colorCssName(contactBg)]: contactBg,
        [colorCssName(contactText, true)]: contactText,
        [this.contactClass]: this.contactClass,
      }
    },
  },

  methods: {
    onClick(e) {
      if (!this.isClickable) {
        return
      }

      this.$emit('click', e)
    },

    onDelete(e) {
      if (this.deletable && !this.disabled) {
        if (this.$attrs.hasOwnProperty('onDelete')) {
          this.$emit('delete', e)
        } else if (this.$attrs.hasOwnProperty('onClick')) {
          this.onClick(e)
        }
      }
    },
  },

  mounted() {
    if (this.tooltip) {
      $(this.$el).tooltip({
        container: 'body',
      })
    }
  },
}
</script>
