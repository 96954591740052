import { URLParams } from '@/munio/http'
import { CollectionResponse } from './data'
import { Api } from './base'

import Access from './Access'
import Accounts from './munio/Accounts.js'
import Account from './munio/Account.js'
import Approvals from './Approvals'
import Approval from './Approval'
import Auth from './Auth'
import Attempt from './Attempt'
import Certificate from './munio/Certificate.js'
import Charts from './munio/Charts.js'
import Companies from './Companies'
import Users from './Users'
import CompanyCourse from './CompanyCourse.js'
import Course from './Course'
import Courses from './Courses'
import CourseManager from './munio/Course'
import CoursesManager from './munio/Courses'
import CurrentUser from './CurrentUser.js'
import Dashboard from './Dashboard.js'
import Departments from './Departments.js'
import DynamicImageGroup from './DynamicImageGroup.js'
import Integration from './Integration'
import Link from './Link.js'
import Locations, { Location } from './Location.js'
import Mobile from './Mobile.js'
import MunioApi from './munio' // "MunioApi" instead of "Munio" to avoid clash with regular Munio object
import OAuth from './OAuth'
import Role from './Role.js'
import Roles from './Roles.js'
import Shop from './Shop.js'
import User from './User.js'
import UserInvite from './UserInvite.js'
import Salesorders from './Salesorders.js'
import Salesorder from './Salesorder.js'
import { AuditResourceDto, AuditContext } from './data/AuditResource.dto'

export class WebApi extends Api {
  get access() {
    return new Access()
  }

  get accounts() {
    return new Accounts()
  }

  account(id: number) {
    return new Account(id)
  }

  get approvals() {
    return new Approvals()
  }

  approval(id: number) {
    return new Approval(id)
  }

  attempt(id: number) {
    return new Attempt(id)
  }

  audits(context?: AuditContext, options?: URLParams) {
    const url = context ? this.$route('api.web.audits.context', context) : this.$route('api.web.audits')

    return this.$http.get<CollectionResponse<AuditResourceDto>>(url, this.$query(options))
  }

  get auth() {
    return new Auth()
  }

  get certificate() {
    return new Certificate()
  }

  checkEmail(email: string) {
    return this.$get(this.$route('api.web.admin.check.email', { email }))
  }

  get companies() {
    return new Companies()
  }

  companyCourse(id: string) {
    return new CompanyCourse(id)
  }

  countries() {
    return this.$get(this.$route('api.web.countries'))
  }

  course(id: number) {
    return new Course(id)
  }

  courseManager(id: number) {
    return new CourseManager(id)
  }

  get courses() {
    return new Courses()
  }

  get currentUser() {
    return new CurrentUser()
  }

  get coursesManager() {
    return new CoursesManager()
  }

  get charts() {
    return new Charts()
  }

  dashboard(companyId: number) {
    return new Dashboard(companyId)
  }

  get departments() {
    return new Departments()
  }

  durationSeconds(seconds: number) {
    seconds = Math.round(seconds)

    return this.$get(this.$route('api.web.admin.duration.seconds', { seconds }))
  }

  integration(id: number) {
    return new Integration(id)
  }

  dynamicImage(group: string) {
    return new DynamicImageGroup(group)
  }

  get link() {
    return new Link()
  }

  get locations() {
    return new Locations()
  }

  location(id: string) {
    return new Location(id)
  }

  mobile(number: string) {
    return new Mobile(number)
  }

  get munio() {
    return new MunioApi()
  }

  get oauth() {
    return new OAuth()
  }

  role(id: string) {
    return new Role(id)
  }

  get roles() {
    return new Roles()
  }

  get salesorders() {
    return new Salesorders()
  }

  salesorder(id: string | number) {
    return new Salesorder(id)
  }

  shop(slug: string | { slug: string } = window.Munio.config.shop.slug, context?: string) {
    return new Shop(slug, context)
  }

  get users() {
    return new Users()
  }

  user(id: string | number) {
    return new User(id)
  }

  userInvite(id: string | number) {
    return new UserInvite(id)
  }
}

window.Munio.api = new WebApi()
