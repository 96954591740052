const { $http, route } = window.Munio

class Action {
  constructor(
    public integrationId: number,
    public ulid: string,
  ) {}

  get params() {
    return {
      integration: this.integrationId,
      action: this.ulid,
    }
  }

  get() {
    return $http.get(route('api.web.integration.action', this.params))
  }

  events(page: number = 1) {
    return $http.get(route('api.web.integration.action.events', this.params), { page })
  }

  retry() {
    return $http.post(route('api.web.integration.action.retry', this.params))
  }

  cancel() {
    return $http.post(route('api.web.integration.action.cancel', this.params))
  }
}

export default Action
