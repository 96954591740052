import { urlParams, queryString } from '@/munio/http'
import { mapArray } from '@/munio/utils'
import { typeIcon } from '@component/GateCardModal/index.vue'
import { trans } from '@/munio/i18n'

const state = {
  gateId: null,
  integrationId: null,
  loading: false,
  paginating: false,
  processing: false,
  transition: 'slide-next',
  currentAction: 'selectingGateCard',
  filter: queryString(),
  stats: {},
  cards: {
    list: [],
    map: {},
    meta: {},
  },
  card: {
    selected: null,
    transition: 'fade',
    showAvatarModal: false,
  },
}

const mutations = {
  SET_GATE(state, gateId) {
    state.gateId = gateId
  },

  SET_INTEGRATION(state, integrationId) {
    state.integrationId = integrationId
  },

  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_PROCESSING(state, value) {
    state.processing = value
  },

  SET_FILTER(state, value) {
    state.filter = queryString(value)
  },

  SET_STATS(state, stats) {
    state.stats = stats
  },

  SET_CARDS(state, { cards, meta, stats }) {
    state.card.selected = null
    state.cards.list = cards
    state.cards.map = mapArray(cards, (card) => card.identifier)
    state.cards.meta = meta
    state.stats = stats
    state.loading = false
    state.paginating = false
    state.processing = false
  },

  SET_CARD(state, card) {
    const cards = state.cards.list
    const index = state.cards.map[card.identifier]

    if (index === undefined) {
      throw new Error('Gate card is not available in the list')
    }

    state.loading = false
    state.processing = false
    state.cards.list = [...cards.slice(0, index), card, ...cards.slice(index + 1)]
  },

  SELECT_CARD(state, identifier) {
    state.card.selected = identifier
  },

  PAGINATE(state, page) {
    if (page) {
      const filter = urlParams(state.filter)
      filter.set('page', page)
      state.filter = filter.toString()
    }

    state.paginating = !!page
  },

  TRANSITION(state, next) {
    if (next) {
      state.transition = 'slide-next'
      state.currentAction = 'matchingGateCard'
    }

    if (!next) {
      state.transition = 'slide-back'
      state.currentAction = 'selectingGateCard'
    }
  },

  SHOW_AVATAR_MODAL(state, show) {
    state.card.showAvatarModal = show
  },
}

const actions = {
  async paginate({ commit, dispatch }, page) {
    commit('PAGINATE', page)
    await dispatch('load')
  },

  async load({ state, commit }, filter = state.filter) {
    if (filter !== state.filter) {
      commit('SET_FILTER', filter)
    }
    commit('SET_LOADING', true)
    try {
      const {
        data: { data: cards, meta, stats },
      } = await Munio.api.access.gate(state.gateId).cards(filter)
      commit('SET_CARDS', { cards, meta, stats })
    } catch (e) {
      console.error(e)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async loadCard({ state, commit }, identifier) {
    commit('SET_LOADING', true)
    const {
      data: { data, stats },
    } = await Munio.api.access.gate(state.gateId).card(identifier).get()
    commit('SET_CARD', data)
    commit('SET_STATS', stats)
  },

  selectCard({ commit }, identifier) {
    commit('SELECT_CARD', identifier)
  },

  async prevCard({ state, commit, dispatch }) {
    const index = state.cards.map[state.card.selected]
    let prev = state.cards.list[index - 1]

    if (prev === undefined && state.cards.meta.current_page > 1) {
      await dispatch('paginate', state.cards.meta.current_page - 1)
      // grab the last card on the previous page
      prev = state.cards.list.slice(-1).pop()
    }

    if (prev) {
      commit('SELECT_CARD', prev.identifier)
      dispatch('loadCard', prev.identifier)
    }
  },

  async nextCard({ state, commit, dispatch }) {
    const index = state.cards.map[state.card.selected]
    let next = state.cards.list[index + 1]

    if (next === undefined && state.cards.meta.current_page < state.cards.meta.last_page) {
      await dispatch('paginate', state.cards.meta.current_page + 1)
      // grab the first card on the next page
      next = state.cards.list[0]
    }

    if (next) {
      commit('SELECT_CARD', next.identifier)
      dispatch('loadCard', next.identifier)
    }
  },

  async match({ state, commit }, { type, user }) {
    if (!state.card.selected || !(await Munio.confirm())) {
      return
    }

    try {
      commit('SET_PROCESSING', true)
      const {
        data: { data },
      } = await Munio.api.access.gate(state.gateId).card(state.card.selected).match(type, user)
      commit('SET_CARD', data)
    } catch (e) {
      Munio.Flash.error(trans('Something went wrong'))
    }
  },
}

const getters = {
  getCard: (state) => (identifier) => {
    const index = state.cards.map[identifier]
    const card = state.cards.list[index]
    card.typeIcon = typeIcon(card.type)
    card.typeColor = null
    if (card.matchedAt) {
      card.typeColor = card.type === 'person' ? 'green-50' : 'blue-50'
    }
    return card
  },

  getPrevCard: (state) => {
    if (!state.card.selected) {
      return undefined
    }

    const index = state.cards.map[state.card.selected]
    const prev = state.cards.list[index - 1]

    if (prev === undefined) {
      if (state.cards.meta.current_page > 1) {
        return true
      }
    }

    return prev
  },

  getNextCard: (state) => {
    if (!state.card.selected) {
      return undefined
    }

    const index = state.cards.map[state.card.selected]
    const next = state.cards.list[index + 1]

    if (next === undefined) {
      if (state.cards.meta.current_page < state.cards.meta.last_page) {
        return true
      }
    }

    return next
  },

  isSelectingCard: (state) => {
    return state.currentAction === 'selectingCard'
  },

  isMatchingCard: (state) => {
    return state.currentAction === 'matchingCard'
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
