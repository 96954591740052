import { Api } from '@/munio/api/base'
import AttemptNode from './AttemptNode'
import { AttemptLegacyDto } from '@/munio/api/data/legacy'
import { CourseConditionType } from '@/munio/definitions/course.conditions'

class Attempt extends Api<{ attempt: number }> {
  constructor(id: number) {
    super({ attempt: id })
  }

  get(language?: string) {
    return this.$get<AttemptLegacyDto>(this.$route('api.web.attempt'), this.$query({ language }))
  }

  changeLanguage(language: string) {
    return this.$post<AttemptLegacyDto>(this.$route('api.web.attempt.language.update'), { language })
  }

  updateCondition(type: CourseConditionType, value: unknown) {
    return this.$post<AttemptLegacyDto>(this.$route('api.web.attempt.condition.update'), { type, value })
  }

  rejectAuth() {
    return this.$post<AttemptLegacyDto>(this.$route('api.web.attempt.auth.reject'))
  }

  confirmAuth() {
    return this.$post<AttemptLegacyDto>(this.$route('api.web.attempt.auth.confirm'))
  }

  node(nodeId: number) {
    return new AttemptNode(this.params.attempt, nodeId)
  }
}

export default Attempt
