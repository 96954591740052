import Action from './Action'
import { URLParamsInput } from '@/munio/definitions'
import {
  CollectionResponse,
  IntegrationResourceDto,
  IntegrationActionResourceDto,
  ResourceResponse,
} from '@/munio/api/data'

const { $http, route, urlParams } = window.Munio

class Integrations {
  constructor(public id: number) {}

  get params() {
    return { integration: this.id }
  }

  get() {
    return $http.get<ResourceResponse<IntegrationResourceDto>>(route('api.web.integration', this.params))
  }

  changeMode(mode: string) {
    return $http.put<ResourceResponse<IntegrationResourceDto>>(
      route('api.web.integration.mode', {
        ...this.params,
        mode,
      }),
    )
  }

  changeOptions(options: Record<string, unknown>) {
    return $http.put<ResourceResponse<IntegrationResourceDto>>(
      route('api.web.integration.options', this.params),
      options,
    )
  }

  addCourse(courseId: number) {
    return $http.post<ResourceResponse<IntegrationResourceDto>>(route('api.web.integration.courses.add', this.params), {
      courseId,
    })
  }

  removeCourse(courseId: number) {
    return $http.delete<ResourceResponse<IntegrationResourceDto>>(
      route('api.web.integration.courses.remove', this.params),
      { data: { courseId } },
    )
  }

  actionsTrigger(action: string) {
    return $http.post<ResourceResponse<IntegrationActionResourceDto>>(route('api.web.integration.actions.trigger'), {
      action,
    })
  }

  actions(options?: URLParamsInput) {
    return $http.get<CollectionResponse<IntegrationActionResourceDto>>(
      route('api.web.integration.actions', this.params),
      urlParams(options),
    )
  }

  action(ulid: string) {
    return new Action(this.id, ulid)
  }
}

export default Integrations
