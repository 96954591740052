import {
  route as routeFn,
  Config,
  Route,
  RouteList,
  RouteParams as ZiggyRouteParams,
  ParameterInfo,
  Router,
} from 'ziggy-js'
import routes from './routes.json'

const currentRoute = window.Munio.config.route
const defaults = { ...currentRoute.defaults, ...currentRoute.params }

type RequiredParams<I extends ParameterInfo[]> = Extract<I[number], { required: true }>
type OptionalParams<I extends ParameterInfo[]> = Extract<I[number], { required: false }>
type DefaultParams = typeof currentRoute.defaults

type KnownRouteParams<I extends ParameterInfo[]> = Omit<
  {
    [T in RequiredParams<I> as T['name']]: string | number
  } & {
    [T in OptionalParams<I> as T['name']]?: string | number
  },
  keyof DefaultParams
>

type RouteName = keyof RouteList

type RouteParams<T extends RouteName> = KnownRouteParams<RouteList[T]> & Record<string, string | number>

export const ziggy: Config = {
  url: currentRoute.base.url,
  port: currentRoute.base.port,
  defaults: defaults,
  routes: routes as Record<RouteName, Route>,
}

export function route(): Router
export function route<T extends RouteName>(name: T, params?: RouteParams<T>): string
export function route<T extends RouteName>(name?: T, params?: RouteParams<T>): Router | string {
  return name
    ? routeFn(name, params as ZiggyRouteParams<T>, true, ziggy)
    : routeFn(undefined, undefined, undefined, ziggy)
}

window.Munio.route = route

export { type RouteName, type RouteParams }
