import { Api } from './base'
import Quiz from '@/munio/api/AttemptQuiz'
import { AttemptLegacyDto, ModulePlayback } from '@/munio/api/data/legacy'
import { ScormModel } from '@app/CoursePlayer/course/types'

class AttemptNode extends Api<{ attempt: number; node: number }> {
  constructor(attemptId: number, nodeId: number) {
    super({ attempt: attemptId, node: nodeId })
  }

  get() {
    return this.$get<ModulePlayback>(this.$route('api.web.attempt.node'))
  }

  startCourse() {
    return this.$post<AttemptLegacyDto>(this.$route('api.web.attempt.node.course.start'))
  }

  approve(date?: string) {
    return this.$post(this.$route('api.web.attempt.node.approve'), {
      completed_at: date,
    })
  }

  videoProgress(metadata: Record<string, unknown>) {
    return this.$beacon(this.$route('api.web.attempt.node.video.progress'), metadata)
  }

  sessionEnroll(id: number) {
    return this.$post(this.$route('api.web.attempt.node.session.enroll', { session: id }))
  }

  sessionUnenroll(id: number) {
    return this.$delete(this.$route('api.web.attempt.node.session.unenroll', { session: id }))
  }

  scorm(itemId: string) {
    return new Scorm(this.params.attempt, this.params.node, itemId)
  }

  get quiz() {
    return new Quiz(this.params.attempt, this.params.node)
  }
}

class Scorm extends Api<{ attempt: number; node: number; item: string }> {
  constructor(attemptId: number, nodeId: number, itemId: string) {
    super({ attempt: attemptId, node: nodeId, item: itemId })
  }

  init() {
    return this.$get<ScormModel>(this.$route('api.web.attempt.node.scorm.initialize'))
  }

  commit(data: unknown, finish = false) {
    return this.$post<ScormModel>(this.$route('api.web.attempt.node.scorm.commit'), { data, finish })
  }
}

export default AttemptNode
